
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

//import bg from '../assets/images/pexels-ksenia-chernaya-3965543HD.jpg';
import icl from '../assets/images/decision-making-1000x630.jpg';

import frase from '../assets/images/WEB-FERMANDO-SANDOVAL.png';

import Article from '../components/Article';
import SubPageStyles from '../styles/SubPageStyles';

const AlianzaStyles = styled.div`
  div {
    padding: 1rem 15%;
    background-color: #ffffffab;
    backdrop-filter: blur(52px);
      &.header {
        padding: 1rem 3rem;
        background: transparent;
      }

    img {
        padding: 3rem;
    }

    ul {
        text-align: left;
    }

    
      p {
        padding: 0 10%;
      }
    
  }

  @media (max-width: 800px) {
    height:auto;
    h1 {
      font-size: 3rem;
    }
    div {
    padding: 1rem 2.2rem;
      &.header {
            padding: 1rem 2rem;
          background: transparent;
    }
    img {
        padding: 0;
    }
  }

  }
`;

export default function ValuesPage() {
  return (
    < AlianzaStyles>
        <Article title="">
            <>
                <h2>Misión</h2>
                <p>
                    “Compartir y transmitir los conocimientos y experiencia adquiridos en mi gestión profesional, a través del coaching y consultoría empresarial, respetando los procesos naturales de las personas y de las organizaciones, para liberar su potencial que les permita emprender y lograr sus metas personales y organizacionales” 
                </p>
                <h3 className="pd-md head ">
                  <img src={frase} />
                </h3>
                <br />

                <img src={icl}></img>
                <br />

                <h2>Valores</h2>
                <br />
                <p>    
                  La focalización, la actualización, el fomento de buenas relaciones interpersonales y laborales, el compromiso con el trabajo la generación de confianzacon las personas y organizaciones que asisto y su acompañamiento seguro.
                </p> 
                <br />

                <Link to="/contacto"><h3>Sobre mí</h3></Link>
                <br />
            </>
        </Article>
    </AlianzaStyles>
  );
}
